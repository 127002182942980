import {graphql, Link, useStaticQuery} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {defaultLangKey, langs} from '../data/languages';
import {getCurrentLangKey, getUrlForLang} from 'ptz-i18n';
import CookieConsent from "react-cookie-consent";

let linkStyle = {
    color: `white`,
    whiteSpace: 'nowrap',
    '@media only screen and (min-width: 768px)': {
        padding: '20px',
    },
    '@media only screen and (max-width: 768px)': {
        width: '100%',
        display: 'inline-block',
        height: '37px',
    },
// textDecoration: `none`,
};

let langDivStyle = {
    position: 'fixed',
    top: '16px',
    right: '0px',
    paddingRight: '10px',
};
let langLinkStyle = {
    color: `white`,
    paddingRight: '15px',
    // textDecoration: `none`,
};
let langLinkStyleSelected = Object.assign({}, langLinkStyle, {
    fontWeight: `bold`,
});
const Header = ({ siteTitle, location, i18nMessages}) => {
    const url = location.pathname;
    const langKey = getCurrentLangKey(langs, defaultLangKey, url);
    console.log('Header', langKey, defaultLangKey)
    const homeLink = `/${langKey}`.replace(`/${defaultLangKey}`, '/');


    let translate = (key) => {
        return i18nMessages[key] || key;
    }
    const data = useStaticQuery(graphql`
    query AllPagesHeaderQuery {
        allSitePage {
            edges {
                node {
                     id
                     path
                }
            }
        }
    }
  `);
    const allPages = data.allSitePage.edges.map(e=>e.node.path);
    const i18nLink = (link) => {
        let lk = `/${langKey}` + link;
        if (allPages.indexOf(lk) >= 0 || allPages.indexOf(lk+'/') >= 0) {
            return lk;
        }
        return link;
    }

    console.log('AllPagesHeaderQuery', allPages);

    return (
        <header style={{ overflow: 'auto',}}>
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: '70%',
                    height: '60px',
                    padding: `1.00rem 1.0875rem`,
                }}
            >
              {/*<span style={langDivStyle} title={translate('Alegeți limba')} >*/}
              {/*    {langs.map(l => {*/}
              {/*        let crtPageLink;*/}
              {/*        if (defaultLangKey === langKey && l !== defaultLangKey) {*/}
              {/*            crtPageLink = `/${l}/`+ url;*/}
              {/*        } else {*/}
              {/*            crtPageLink = url.replace(`/${langKey}/`, `/${l === defaultLangKey ? '' : l}/`);*/}
              {/*        }*/}
              {/*        if (allPages.indexOf(crtPageLink) < 0) {*/}
              {/*            crtPageLink = `/${l === defaultLangKey ? '' : l}/`;*/}
              {/*        }*/}
              {/*        return <Link to={crtPageLink} style={l === langKey ? langLinkStyleSelected : langLinkStyle}>*/}
              {/*            {l}*/}
              {/*        </Link>;})}*/}
              {/*</span>*/}

                <div style={{ margin: 0 }}>
                    <div style={{float: 'left'}}>
                        <Link
                            to={homeLink || "/"}
                            css={linkStyle}
                        >
                            {siteTitle}
                        </Link>

                    </div>
                    <div style={{float: 'right'}}>

                        <Link to={i18nLink("/demo/")} css={linkStyle}>
                            {translate('DEMO')}
                        </Link>

                        <Link to={i18nLink("/analizator/")} css={linkStyle}>
                            {translate('ANALIZATOR')}
                        </Link>

                        <Link to={i18nLink("/resurse/")} css={linkStyle}>
                            {translate('RESURSE')}
                        </Link>

                        <Link to={i18nLink("/forum/")} css={linkStyle}>
                            {translate('FORUM')}
                        </Link>

                        <Link to={i18nLink("/contact/")} css={linkStyle}>
                            {translate('CONTACT')}
                        </Link>

                    </div>
                </div>
            </div>
            <CookieConsent buttonText="Accept">
                Folosim cookie-uri pentru a personaliza conținutul și anunțurile, pentru a oferi funcții de rețele sociale și pentru a analiza traficul.
                În cazul în care alegeți să continuați să utilizați website-ul nostru, sunteți de acord cu utilizarea modulelor noastre cookie.
            </CookieConsent>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
