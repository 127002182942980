/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby"
import {getCurrentLangKey} from 'ptz-i18n';
import {addLocaleData} from 'react-intl';


import Header from "./header"
import "./layout.css"

import 'intl';
import {defaultLangKey, langs} from '../data/languages';

import ro  from 'react-intl/locale-data/ro';
import 'intl/locale-data/jsonp/ro';
import i18nMessages from '../data/messages/ro';

addLocaleData(ro);




const Layout = ({ children, location }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQueryRo {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);


    const url = location.pathname;
    const langKey = getCurrentLangKey(langs, defaultLangKey, url);
    const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');


    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} location={location}  i18nMessages={i18nMessages}/>
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    padding: `0px 1.0875rem 1.45rem`,
                    minHeight: '100%',
                    paddingTop: 0,
                }}
            >
                <main>{children}</main>
            </div>
            <footer>
            <span style={{float: 'right'}}>
              © {new Date().getFullYear()},
                {` `}
                <Link to={homeLink || "/"} style={{color: `white`}}>analiza-gramaticala.ro</Link>
            </span>
            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
